import React, { Component } from "react";
import HeadingComponent from "@/components/ui/HeadingComponent";
import Paragraph from "@/components/ui/Paragraph";

export default class EndToEndAnalyticsMicrofinance extends Component {
  render() {
    return (
      <>
        <div className="page-section mb-10">
          <div className="page-container">
            <HeadingComponent level={3}>The Challenge</HeadingComponent>
            <Paragraph>
              As data grew rapidly from a few hundred thousand to a few million
              records per dataset, the regular desktop BI solution along with
              Excel began to pose a problem in itself.
            </Paragraph>
            <Paragraph>
              Further complicating the issue was the lack of version management
              and domain modeling resulting in large number of ad-hoc queries
              from the business and executive teams.
            </Paragraph>
            <Paragraph>
              Data resided in various databases/repositories often known only to
              a closed group of Business Analyst.
            </Paragraph>
            <Paragraph>
              Business User Expectation on quality and speed of insights and
              number of users asking such question grew rapidly. Just-in-time
              Adhoc analysis became a non-starter.
            </Paragraph>
          </div>
        </div>
        <div className="page-section mb-10">
          <div className="page-container">
            <HeadingComponent level={3} className="mb-4">
              The Solution
            </HeadingComponent>
            <HeadingComponent className="">
              Integrated Data & AI Platform Using Xceed Intelligence Platform
            </HeadingComponent>
            <Paragraph>
              Over a period of very short time, all the offline/online data
              sources were onboarded into Xceed Intelligence Platform and 30
              plus data workflows including many of the data
              cleanup/transformation workflows were onboarded on Xceed
              Intelligence Platform, Domain Models were built rapidly using
              Xceed SDK. Further, Monthly and Daily Dashboards for various
              functional groups including HR, Finance and Business Operations
              were created.
            </Paragraph>
          </div>
        </div>
        <div className="page-section mb-10">
          <div className="page-container">
            <HeadingComponent level={3}>Benefits & Next Steps</HeadingComponent>
            <ol>
              <li>
                Unified interface enabled various teams from Business
                Executives, Business Operations, HR, Sales Operations
                collaborate seamlessly both with data and with other teams.
              </li>
              <li>
                End-to-end automation ensured that all the user dashboards were
                seamlessly updated on availability of newer data.
              </li>
              <li>
                Availability of versioned data centrally ensured access to data
                was possible across teams of data users and collaborators and
                also time trail to fetch a past data.
              </li>
              <li>
                Domain Models ensured that a variety of queries were answered
                instantly without needing to prepare data instantly.
              </li>
              <li>
                Integrated Self-service Dashboard further helped perform ad-hoc
                analysis, if further modeling is required.
              </li>
              <li>
                In Months to come, Financial Institution also plans to do
                predictive modeling using Xceed Intelligence Platform.
              </li>
            </ol>
          </div>
        </div>
      </>
    );
  }
}
